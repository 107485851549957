import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    counter: 1000,
    screenWidth:950
  },
  mutations: {
    increment(state) {
      state.counter++;
    },
    decrement(state) {
      state.counter--;
    },
    setScreenWidth: (state, screenWidth) => {
        state.screenWidth = screenWidth
      },
    },
});