<template>
		<div class="head">
				<div v-if="screenWidth > 950" style="margin-bottom:20px">
					<el-menu :default-active="activeIndex" router class="el-menu-demo" :collapse="isCollapse" mode="horizontal">
						<img @click="gotoHome" src="../assets/logo.jpg" style="height:60px;line-height:60px;float:left;margin:0 50px 0px 20px;outline: none;cursor: pointer;" />
						<el-menu-item index="/">首页</el-menu-item>
						<el-submenu index="2">
							<template slot="title"><span class="title">关于我们</span></template>
							<el-menu-item index="article?id=152">公司介绍</el-menu-item>
							<el-menu-item index="article?id=155">发展历程与规划</el-menu-item>
              <el-menu-item index="article?id=156">专利证书</el-menu-item>
						</el-submenu>
						<el-submenu index="3">
							<template slot="title"><span class="title">产品体系</span></template>
							<!-- <el-menu-item index="article?id=164">呵呵管</el-menu-item> -->
							<el-menu-item index="article?id=165">菲长乐</el-menu-item>
							<el-menu-item index="article?id=161">呵佑宁</el-menu-item>
							<el-menu-item index="article?id=163">呵佐安</el-menu-item>
							<el-menu-item index="article?id=162">菲佑宁</el-menu-item>
							<el-menu-item index="article?id=160">菲佐安</el-menu-item>
							<!-- <el-menu-item index="article?id=175">科安板</el-menu-item> -->
						</el-submenu>
						<el-menu-item index="list">公司动态</el-menu-item>
						<el-submenu index="5">
							<template slot="title"><span class="title">联系我们</span></template>
							<el-menu-item index="article?id=157">招贤纳士</el-menu-item>
              <el-menu-item index="article?id=158">合作伙伴</el-menu-item>
							<el-menu-item index="article?id=159">联系方式</el-menu-item>
						</el-submenu>
					</el-menu>
				</div>
				<div v-else>
					<div style="background-color:#30889e">
						<img src="../assets/logo2.png" style="height:42px;line-height:42px;float:left;margin:10px 0 0px 20px" />
						<div style="padding:10px 20px 10px 10px;height:40px" @click="isCollapse = !isCollapse">
							<img src="../assets/index/fold2.png" width="40px" style="float:right"/>
						</div>
					</div>
					<el-menu v-show="isCollapse" :default-active="activeIndex" :unique-opend="true" router class="el-menu-demo2" :collapse="false" @select="handleSelect">
						<el-menu-item index="/">首页</el-menu-item>
						<el-submenu index="2">
							<template slot="title"><span class="title">关于我们</span></template>
							<el-menu-item index="article?id=152">公司介绍</el-menu-item>
							<el-menu-item index="article?id=155">发展历程与规划</el-menu-item>
              <el-menu-item index="article?id=156">专利证书</el-menu-item>
						</el-submenu>
						<el-submenu index="3">
							<template slot="title"><span class="title">产品体系</span></template>
							<!-- <el-menu-item index="article?id=164">呵呵管</el-menu-item> -->
							<el-menu-item index="article?id=165">菲长乐</el-menu-item>
							<el-menu-item index="article?id=161">呵佑宁</el-menu-item>
							<el-menu-item index="article?id=163">呵佐安</el-menu-item>
							<el-menu-item index="article?id=162">菲佑宁</el-menu-item>
							<el-menu-item index="article?id=160">菲佐安</el-menu-item>
							<!-- <el-menu-item index="article?id=175">科安板</el-menu-item> -->
						</el-submenu>
						<el-menu-item index="list">公司动态</el-menu-item>
						<el-submenu index="5">
							<template slot="title"><span class="title">联系我们</span></template>
							<el-menu-item index="article?id=157">招贤纳士</el-menu-item>
              <el-menu-item index="article?id=158">合作伙伴</el-menu-item>
							<el-menu-item index="article?id=159">联系方式</el-menu-item>
						</el-submenu>
					</el-menu>
				</div>
		</div>
</template>

<script>
export default {
	data() {
		return {
			activeIndex: '10',
			screenWidth:0,
			isCollapse:false,
			isShow:true
		}
	},
	methods: {
		gotoHome() {
			this.$router.push('/')
		},
		handleSelect(key, keyPath) {
			this.isCollapse = !this.isCollapse
		},
		fetchData() {
			var path = this.$route.fullPath; //获取当前路由
			this.activeIndex = path === "/" ? "/" : path.substring(1)
		},
		_isMobile() {
			let flag = document.body.clientWidth;
			return flag;
		}
	},
	created() {
		this.fetchData()
		window.screenWidth = document.body.clientWidth
    this.screenWidth = window.screenWidth
	},
	watch: {
		'$route': 'fetchData'
	},
	mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
				this.$store.commit("setScreenWidth",that.screenWidth);
        console.log(this.$store.state.screenWidth);
      })()
    }
  }
}
</script>
<style scoped>
.el-menu-demo {
	width: 100%;
	margin: 0 auto;
	margin-top: 20px;
}
.el-menu-demo2 {
	width: 100%;
	margin: 0 auto;
	background-color: #f9ffff;
}
.el-menu.el-menu--horizontal {
	border: none;
}
.el-menu--horizontal > .el-menu-item, .title{
	color: #333;
}
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
}
.hamburger.is-active {
  transform: rotate(180deg);
}
</style>