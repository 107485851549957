<template>
  <div class="footer">
    <div v-if="screenWidth < 800">
      <div class="record">
        <div class="logo"><img src="../assets/logo.png" /></div>
        <div class="des">
          <div class="txt1">Copyright © 2012-2022 JFK BIOTECH.</div>
          <div class="txt2">浙江今复康生物科技有限公司（浙ICP备13004725）公安备案号：11010102002019 </div>
        </div>
      </div>
    </div>
    <div v-else class="big">
      <div style="padding:50px">
        <div class="logo" style="float:left"><img src="../assets/logo.png" /></div>
        <div class="des" style="float:right;height:90px;line-height:90px;padding-top:20px">
          <div class="txt1">Copyright © 2012-2022 JFK BIOTECH.</div>
          <div class="txt2">浙江今复康生物科技有限公司（浙ICP备13004725）公安备案号：11010102002019 </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
}
</script>
<style scoped>
.footer {
  bottom: 0;
  position:relative;
  text-align: center;
  background-color: #30889e;
  height: 180px;
  width: 100%;
  min-width: 100%;
}
.logo img {
  width:240px;
  margin-top: 10px;
}
.des .txt1, .des .txt2 {
  height: 30px;
  line-height: 30px;
  font-size: 0.9rem;
  padding: 0 20px;
  color: #fff;
}
.footer .footermenu{
    width: 85%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 0.5em;
}
.footer .footermenu .fleft{
    width: 70%;
}
.footer .footermenu .fleft ul {
    float: left;
    width: 7rem;
    list-style: none;
    margin: 0;
}
 .bigmenu {
    margin-bottom: 0.5rem;
}
.footer .footermenu .fleft ul li a{
    color: #2c4456;
}
.footer .footermenu .fleft ul li {
    padding: 0.125em 0;
    font-size: 0.9rem;
}
.big{
  text-align:center;
  width:80%;
  padding-left: 10%;
}
</style>
